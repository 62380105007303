<script lang="ts" setup>
const { getShopData } = useShopData();

const shopCover = computed(() => getShopData.value?.cover || '');
</script>

<template>
  <div class="shop-header relative max-w-(--breakpoint-2xl) overflow-hidden bg-white">
    <div
      v-if="shopCover"
      class="shop-header-cover relative px-1 pt-1 lg:px-5 lg:pt-2"
    >
      <div
        class="shop-header-cover-background relative left-0 top-0 size-full opacity-50 blur-[100px]"
        :style="{ backgroundImage: `url(${shopCover})` }"
      />
      <div class="shop-header-cover-background-fitter lg:relative lg:left-0 lg:top-0 lg:-ml-5 lg:h-5 lg:w-full lg:bg-white" />
      <nuxt-img
        aspect-ratio="4/1"
        class="relative m-auto aspect-4/1 w-full max-w-[1050px] overflow-hidden rounded-lg object-cover"
        provider="cloudflare"
        :alt="getShopData?.shopName || ''"
        :src="shopCover"
        :width="1050"
      />
    </div>
    <ShopInformation class="relative -top-3 md:top-0" />
  </div>
</template>
